.header {
  position: fixed;
  z-index: 5;
  top: 0;
  /* max-width: 1440px; */
  width: 100%;
  margin: 0 auto;
  background-color: var(--secondary-bg);
  padding-right: 15px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.betaSignUpButton {
  display: flex;
  align-items: center;
  padding: 0px 10px;

  /* width: 125px; */
  height: 42px;
  font-family: "Space Grotesk", sans-serif;
  background-color: #c9ab84;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dragonIcon {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.languageChangingDropdown {
  margin-left: 15px;
}

.logoContainer {
  background-color: var(--secondary-bg);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
}

.logoContainer {
  cursor: pointer;
}

.logo {
  width: 206.37px;
}

.hamburger {
  display: none;
}

.hamburger svg {
  color: var(--primary-color);
  font-size: 24px;
  cursor: pointer;
}

.close {
  color: var(--primary-color);
  font-size: 32px;
  display: none;
  cursor: pointer;
}

.adressAndLanugage {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contactAddress {
  font-family: "Space Grotesk";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 125%;
  color: #242424;
  mix-blend-mode: normal;
  background: #c9ab84;
  border-radius: 14px;
  padding: 8px 18px;
  height: 42px;
  display: flex;
  align-items: center;
}

.hrefContactAddress {
  color: #242424;
  text-decoration: none;
}

.headerLogo {
  display: none;
}

.languageChangingDropdown {
  border: 0.5px solid #c9ab84;
  border-radius: 14px;
  display: flex;
  align-items: center;
  position: relative;
  width: 90px;
  height: 42px;
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  .languageChangingDropdown {
    display: none;
  }
}

.languageChangingDropdown:hover {
  overflow: visible;
  border-radius: 14px 14px 0 0;
}

.languageChangingDropdown .dropbtn {
  border: none;
  cursor: pointer;
  border-bottom: 0.5px solid transparent;
  border-radius: 14px;
  padding: 8px 12px;
  width: 100%;
  height: 100%;
}

.languageChangingDropdown:hover .dropbtn {
  border-bottom: 0.5px solid #c9ab84;
  width: 100%;
}

.languageChangingDropdown .dropbtn .languageAndCountryName {
  font-family: "Space Grotesk";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  color: #c9ab84;
  mix-blend-mode: normal;
  display: flex;
  align-items: center;
  gap: 15px;
}

.languageChangingDropdown .iconAndCountry {
  display: flex;
  align-items: center;
  gap: 5px;
}

.dropdownHeader .arrow {
  color: var(--primary-color);
  cursor: pointer;
}

.languageChangingDropdown .dropdownContent {
  display: none;
  position: absolute;
  top: 40px;
  right: -1px;
  background-color: var(--secondary-bg);
  width: calc(100% + 2px);
  z-index: 1;
  border: 1px solid var(--primary-color);
  border-top: none;
  border-radius: 0 0 14px 14px;
  padding: 8px 6px;
  flex-direction: column;
  align-items: start;
}

.languageChangingDropdown .dropdownContent a {
  display: flex;
  align-items: center;
  gap: 7px;
  font-family: "Space Grotesk";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  color: #c9ab84;
  mix-blend-mode: normal;
  padding: 6px 8px;
}

.languageChangingDropdown .flag {
  width: 18px;
  height: 18px;
}

.languageChangingDropdown:hover .dropdownContent {
  display: flex;
}

.languageChangingDropdown .dropdownContent:hover {
  display: flex;
}

.languageChangingDropdown .downArrow {
  color: var(--primary-color);
}

@media only screen and (max-width: 1440px) {
  .contactAddress {
    font-size: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .header {
    display: grid;
    grid-template-columns: 90px 1fr 90px;
    padding: 0 15px;
    gap: 20px;
  }

  .headerLogo {
    display: block;
    margin: 0 auto;
  }

  .logoContainer {
    display: none;
  }

  .hamburger {
    display: flex;
    justify-content: flex-start;
  }

  .close {
    display: flex;
  }

  .contactAddress {
    display: none;
  }
}

@media only screen and (max-width: 520px) {
  .header {
    display: grid;
    grid-template-columns: 70px 1fr 70px;
    padding: 0 10px;
    gap: 12px;
  }

  .languageChangingDropdown {
    width: 70px;
  }

  .languageChangingDropdown .dropbtn {
    padding: 8px;
  }

  .languageChangingDropdown .dropbtn .languageAndCountryName {
    gap: 10px;
  }

  .languageChangingDropdown .flag {
    width: 12px;
    height: 12px;
  }
}

@media only screen and (max-width: 400px) {
  .headerLogo {
    width: 150px;
  }
}

@media only screen and (max-width: 360px) {
  .header {
    grid-template-columns: 60px 1fr 70px;
  }
}

.betaSignUpButton {
  display: flex;
}

@media only screen and (max-width: 992px) {
  .betaSignUpButton {
    display: none;
  }
}
