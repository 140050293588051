.signupContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #c9ab84;
}

.signupTitle {
  font-size: 2em;
  margin-bottom: 30px;
  color: #c9ab84;
}

.formCard {
  border: 1px solid #c9ab84;
  padding: 50px 20px;
  width: 100%;
  border-radius: 14px;
  position: relative;
}

.formSectionTitle {
  font-size: 1.5em;
  padding: 8px 25px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  color: #c9ab84;
  border: 1px solid #c9ab84;
  border-radius: 14px;
}

.formContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

.signupForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.telRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  max-width: 50%;
}

.inputField {
  flex: 1;
  padding: 10px 15px;
  background-color: transparent;
  border: 1px solid #c9ab84;
  color: #c9ab84;
  border-radius: 14px;
  margin: 5px;
  font-size: 0.9em;
  box-sizing: border-box;
}

.fullWidth,
.signupButton,
.phoneInput {
  max-width: 272px;
}

.signupButton {
  background-color: #c9ab84;
  color: #111;
  border: none;
  padding: 10px;
  font-size: 1em;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
  margin: 5px 0 0 5px;
  width: 50%;
}

.victoryImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background-color: #c9ab84; */
}

.victoryImage img {
  width: 40vw;
  max-width: 80%;
  height: auto;
}

.bulletpoint {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-right: 1.5rem;
  margin-left: 2rem;
  color: white; /* Or any color to make the text visible */
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .victoryImage img {
    width: 60vw;
  }
  .inputRow {
    display: flex;
    flex-direction: column;
    margin-right: 0.5rem;
  }
  .bulletpoint {
    margin-left: 1rem;
  }
  .bulletpoint img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media (max-width: 480px) {
  .victoryImage img {
    width: 80vw;
  }
  .bulletpoint {
    margin-left: 1rem;
  }
  .inputRow {
    display: flex;
    flex-direction: column;
  }
  .bulletpoint img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media (max-width: 992px) {
  .victoryImage {
    max-width: 100%;
  }
  .bulletpoint {
    margin-left: 1rem;
  }
  .inputRow {
    display: flex;
    flex-direction: column;
  }
  .bulletpoint img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media (max-width: 768px) {
  .victoryImage {
    max-width: 100%;
  }
  .bulletpoint img {
    width: 1.5rem;
    height: 1.5rem;
  }

  .bulletpoint {
    margin-left: 1rem;
  }
  .formContent {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
  }
  .inputRow {
    display: flex;
    flex-direction: column;
  }
  .formCard {
    border: 1px solid #c9ab84;
    padding: 35px 5px;
    width: 100%;
    border-radius: 14px;
    position: relative;
  }

  .signupForm,
  .victoryImage {
    margin: 0 auto;
  }
  /* .signupForm {
    align-items: center;
    justify-content: center;
  } */
  .signupButton {
    width: 100%;
    margin-left: 2.2rem;
  }

  .inputField {
    width: 100%;
  }
}

.cardOverlay {
  width: 100%;
  height: 437px !important;
  background-color: rgba(0, 0, 0, 0.7);
  /* border-radius: 14px; */
  text-align: center;
  border: 1px solid #c9ab84;
  box-shadow: 0 4px 10px rgba(243, 222, 136, 0.3);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding-right: 2px;
}

.cardContent {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.prizeTitle {
  font-family: "Space Grotesk", sans-serif;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
  color: #c9ab84;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 1rem;
}

.bulletText {
  font-size: 20px;
  margin-left: 13px;
  text-decoration: none;
  text-align: left; /* Explicitly aligns text to the left */
}

.betaSignUpButton {
  width: 100%;
  height: 60px;
  padding-top: 1.1rem;
  margin-right: 0.5rem;
  font-family: "Space Grotesk", sans-serif;
  font-size: 20px;
  color: #242424;
  background-color: #c9ab84;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  bottom: 0px;
  margin-top: auto;
}
.bulletpoint img {
  width: 1.6rem;
  height: 1.6rem;
}
